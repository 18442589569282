import "./App.css";
import Routing from "../src/routes/routes";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
