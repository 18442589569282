import React, { lazy, Suspense, Fragment, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

function Routing() {
  // Lazy load components
  const Nav = lazy(() => import("../components/navbar.comp"));
  const Portfolio = lazy(() => import("../views/portfolio/portfolio.view"));
  // const Blog = lazy(() => import("../views/blog/blog.view"));
  const CTY = lazy(() =>
    import("../views/contact-thankyou/contact-thankyou.view")
  );
  const LandingGeneral = lazy(() =>
    import("../views/landing-general/landing-general")
  );

  const LoadStaticHome = () => {
    useEffect(() => {
      window.location.replace(`/home.html`);
    }, []);
    return <></>;
  };
  const LoadStaticServices = () => {
    useEffect(() => {
      window.location.replace(`/development-for-startups.html`);
    }, []);
    return <></>;
  };
  const LoadStaticContact = () => {
    useEffect(() => {
      window.location.replace(`/contact.html`);
    }, []);
    return <></>;
  };
  const LoadStaticStartupDevelopment = () => {
    useEffect(() => {
      window.location.replace(`/development-for-startups.html`);
    }, []);
    return <></>;
  };
  const LoadStaticWebsiteDevelopment = () => {
    useEffect(() => {
      window.location.replace(`/website-development.html`);
    }, []);
    return <></>;
  };
  const LoadStaticAppDevelopment = () => {
    useEffect(() => {
      window.location.replace(`/mobile-app-development.html`);
    }, []);
    return <></>;
  };
  // const LoadStaticBlogPage = () => {
  //   useEffect(() => {
  //     window.location.replace(`/blog.html`);
  //   }, []);
  //   return <></>;
  // };

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Fragment>
          <Routes>
            <Route path="/" element={<LoadStaticHome />} exact />
            <Route
              path="/services"
              element={<Navigate replace to="/development-for-startups" />}
              exact
            />
            <Route path="/contact" element={<LoadStaticContact />} exact />
            <Route
              path="/development-for-startups"
              element={<LoadStaticStartupDevelopment />}
              exact
            />
            <Route
              path="/Website-development"
              element={<LoadStaticWebsiteDevelopment />}
              exact
            />
            <Route
              path="/mobile-app-development"
              element={<LoadStaticAppDevelopment />}
              exact
            />
            <Route path="/cty" element={<CTY />} exact />
            <Route exact path="/development-services">
              <Route
                path="/development-services"
                element={<Nav ElementBody={<LandingGeneral />} nothingSelected/>}
                exact
              />
            </Route>
            {/* <Route
              path="/blog"
              element={<Blog />}
              exact
            /> */}
            <Route exact path="/portfolio">
              <Route
                path="/portfolio"
                element={<Nav ElementBody={<Portfolio />} />}
                exact
              />
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} exact />
          </Routes>
        </Fragment>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routing;
